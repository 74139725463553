// Core variables and mixins
@import "../../bootstrap/functions";
@import "../../bootstrap/mixins";

// Core variables and mixins overrides
@import "../../core/variables/variables";
@import "../../bootstrap/variables";

// Overrides user variable
@import "../../core/variables/components-variables";

// For Tour Styling

.tippy-popper{
  border: none;
  border-radius: .5rem;
  filter: drop-shadow(0 0 7px rgba(0,0,0,.5));
  // tour width
  .tippy-tooltip{
    width: auto !important;
    // border-color
    .tippy-arrow{
      border-bottom-color: $primary;
    }

    // content bg, padding, radius
    .tippy-content{
      .shepherd-content{
        background-color: $primary;
        padding: 1.3rem 1rem;
        border-radius: .5rem;

        // header padding
        .shepherd-header{
          padding: 0;
        }
        // text color, font-size
        .shepherd-text{
          color: $white;
          font-size: 1rem;
        }

        // footer margin, padding, bg-color, border
        .shepherd-footer{
          padding: 0;
          .shepherd-buttons{
            li{
              margin: 0;
            }

            .shepherd-button {
              background-color : transparent;
              border: 1px solid $white;
              border-radius: 6px;
              padding: .7rem 2rem;
              color: $white;
              margin: 0 5px;
              &:hover{
                background-color: rgba($white, .1);
              }
          }

          }
        }
      }
      // to align footer buttons at center
      [data-shepherd-step-id="step-4"]{
        .shepherd-footer{
          .shepherd-buttons{
            text-align: center;
          }
        }
      }
    }
  }
}

// For border-color on placement

.tippy-popper{
  &[x-placement="top"]{
    .tippy-arrow{
      border-color: transparent;
      border-top-color: $primary;
    }
  }
  &[x-placement="bottom"]{
    .tippy-arrow{
      border-color: transparent;
      border-bottom-color: $primary;
    }
  }
  &[x-placement="right"]{
    .tippy-arrow{
      border-color: transparent;
      border-right-color: $primary;
    }
  }
  &[x-placement="left"]{
    .tippy-arrow{
      border-color: transparent;
      border-left-color: $primary;
    }
  }
}
